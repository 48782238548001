import React, {useState, useEffect} from 'react';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {} from '@fortawesome/react-fontawesome'
import './App.css';
import img from './mockup.png';


function App() {
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('')
    const [hasSearched, setHasSearched] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    const handleSearch = async () => {
        setHasSearched(true)
        setLoading(true)
        const response = await fetch(`https://phonesearch.api.gofyeo.com/search/${searchValue}`, {method: "GET"})
        const resp = await response.json()
        if (response.ok) {
            setCount(resp.length)
            setLoading(false)
        } else {
            setLoading(false)
        }

    }
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);


    // @ts-ignore
    const handleChange = (event) => {
        setSearchValue(event.target.value);
        setHasSearched(false);
    };
    // @ts-ignore
    const onSubmit = (event) => {
        event.preventDefault()
    }
    return (
        <div className="">
            <div className="column searchContainer">
                <div className="row  headerBackground">
                    <div className=" row logo">
                       <a className="logoLink" href="https://www.gofyeo.com/" target="_blank"> F Y E O</a>
                    </div>
                    <div className="row">
                        {!hasSearched && <span className={isMobile ? "exposureHeaderMobile" : "exposureHeader"}>Has your phone number or email been leaked?</span>}
                        <span className={isMobile ? "exposureHeaderMobile" : "exposureHeader"}>
                                           {loading && <FontAwesomeIcon icon={faSpinner} className="spinning"/>}
                            {count === 0 && hasSearched && !loading && "Hooray - we've got nothing on you"}
                            {count > 0 && !loading && hasSearched && <div>Ah snap - your digits have <br/> have been leaked :( </div>}
                    </span>
                    </div>
                    <div className=" row">
                    <span className={isMobile ? "exposureMessageMobile": "exposureMessage"}>
                                            {hasSearched && !loading && count > 0 && (
                                                `${searchValue} has appeared in ${count} number of breaches`
                                            )}
                    </span>
                        <span className={isMobile ? "exposureMessageMobile": "exposureMessage"}>
                            {hasSearched && !loading && count == undefined && (
                                `Please enter a valid email or a telephone number starting with the country code`
                            )}
                        </span>
                        {!hasSearched && (
                            <span className={isMobile ? "exposureMessageMobile": "exposureMessage"}> Prevent identity fraud. Your email + phone number is the perfect match for {isMobile ? "" : <br />} cybercriminals to initiate takeovers of your online accounts.
                                         </span>
                        )}
                    </div>
                    <div className=" row formContainer" >
                        <form onSubmit={onSubmit}>
                            <div className="input-container">
                                <input type="text" className="input-field" onChange={handleChange} value={searchValue}
                                       placeholder="Enter your email or phone number"/>
                                <button className="input-button" onClick={handleSearch}>Search</button>
                            </div>
                        </form>
                    </div>
                    <div className= {isMobile ? " row formatMessageMobile": "row formatMessage"}>
                        For phone search: format must be country code + phone number with no spaces. (example +19705551234)
                    </div>
                </div>
                <div className="row">
     <span className={isMobile ? "exposureMessageBlackMobile" : "exposureMessageBlack"}>
                       Download the raw hashed research data <a href="https://s3.us-west-1.amazonaws.com/phonesearch.api.gofyeo.com/files/full_export.zip" target="_blank"> here</a>. (File size 22.8GB)
                    </span>
                </div>
                <div className={isMobile ? "column" : "row"}>

                    <span className={isMobile ? "mockupImageMobile" : "mockupImage"}>
                        <img className={isMobile ? "mockImgMobile" : "mockImg"} src={img} alt="fyeo di"/>
                    </span>
                    <span className={isMobile ? "mockupTextMobile" : "mockupText"}>
                                            <p className={isMobile ? "textBoldMobile" : "boldText"}>
                         FYEO Identity: decentralized password management and identity monitoring made simple
                        </p>

                        <p className= {isMobile ? "" : "pwText"}>
                             The FYEO Identity (“FYEO ID”) password manager is based on private key technology that generates passwords from strong key material that is cryptographically secure with a high information entropy.With real-time identity monitoring built-in to the platform, FYEO ID has you covered. FREE.
                         </p>
                         <p className={isMobile ? "textBoldMobile" : "boldText"}>Want to see where you are leaked and what passwords you need to change? <br/> Join the beta!</p>
                         <a href="https://www.gofyeo.com/fyeo-identity-closed-beta" target="_blank" className="myButton">FYEO Identity Beta</a>
                    </span>
                </div>
            </div>

            <div>
                <div className="">
                    <footer className="row footer">
                        <a href="https://www.gofyeo.com/" target="_blank" className="footerLink">gofyeo.com</a>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default App;